const palleteDark = () => {
	document.querySelector(":root").style.backgroundColor = "#1c2039";
	document
		.querySelector(":root")
		.style.setProperty("--background_primary", "#1c2039");
	document
		.querySelector(":root")
		.style.setProperty("--background_secondary", "#262b49");
	document
		.querySelector(":root")
		.style.setProperty("--background_tertiary", "#484b6a");
	document
		.querySelector(":root")
		.style.setProperty("--fontColor-primary", "#fefffe");
	document
		.querySelector(":root")
		.style.setProperty("--fontColor-secondary", "#8c8c92");
		document
		.querySelector(":root")
		.style.setProperty("--fontColor-eo", "#8c8c92");
	document
		.querySelector(":root")
		.style.setProperty("--shadow_primary", "rgba(136, 136, 136, 0.3) 0px 2px 20px");
};

export default palleteDark;
